/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { CacheableResponsePlugin } from 'workbox-cacheable-response'
import { clientsClaim } from 'workbox-core'
import { ExpirationPlugin } from 'workbox-expiration'
import { precacheAndRoute, createHandlerBoundToURL } from 'workbox-precaching'
import { registerRoute } from 'workbox-routing'
import {
  StaleWhileRevalidate,
  CacheFirst,
  NetworkFirst,
} from 'workbox-strategies'

clientsClaim()

import getEnv from '../utils/getEnv'

const { VITE_APP_API_URL } = getEnv()

/**
 * We are not wrapping it in a 'message' event as per the new update.
 * @see https://developers.google.com/web/tools/workbox/modules/workbox-core
 */
self.skipWaiting()

async function checkForUpdates() {
  const cache = await caches.open('pwa-assets-manifest')
  const cachedResponse = await cache.match('/asset-manifest.json')
  let cachedVersion = null

  if (cachedResponse) {
    const cachedManifest = await cachedResponse.json()
    cachedVersion = cachedManifest.version
  }

  const response = await fetch('/asset-manifest.json')
  const currentManifest = await response.clone().json()
  const currentVersion = currentManifest.version

  if (currentVersion !== cachedVersion) {
    console.info('New version detected, updating service worker...')
    // Update the cached manifest version
    await cache.put('/asset-manifest.json', response)
    // This will skip waiting and activate new service worker immediately
    self.skipWaiting()
  }
}

self.addEventListener('install', (event) => {
  console.info('Checking for updates')
  // event.waitUntil(checkForUpdates())

  event.waitUntil(
    checkForUpdates().then(() => {
      console.log('Update check complete, skipping waiting phase.')
      self.skipWaiting()
    })
  )
})

self.addEventListener('activate', (event) => {
  console.log('Service worker activating, clearing old caches...')
  event.waitUntil(
    caches
      .keys()
      .then((cacheNames) => {
        return Promise.all(
          cacheNames.map((cacheName) => {
            console.log(`Deleting old cache: ${cacheName}`)
            return caches.delete(cacheName)
          })
        )
      })
      .then(() => {
        console.log('Old caches cleared, service worker now active.')
      })
  )
  clientsClaim()
})

/**
 * Precache all of the assets generated by your build process.
 * Their URLs are injected into the manifest variable below.
 * This variable must be present somewhere in your service worker file,
 * even if you decide not to use precaching. See https://cra.link/PWA
 */
precacheAndRoute(self.__WB_MANIFEST)

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
// eslint-disable-next-line prefer-regex-literals
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$')
registerRoute(
  // Return false to exempt requests from being fulfilled by index.html.
  ({ request, url }) => {
    // If this isn't a navigation, skip.
    if (request.mode !== 'navigate') {
      return false
    } // If this is a URL that starts with /_, skip.

    if (url.pathname.startsWith('/_')) {
      return false
    } // If this looks like a URL for a resource, because it contains // a file extension, skip.

    if (url.pathname.match(fileExtensionRegexp)) {
      return false
    } // Return true to signal that we want to use the handler.

    return true
  },
  createHandlerBoundToURL('/index.html')
)

// registerRoute(
//   ({ url }) => {
//     const apiOrigin = new URL(VITE_APP_API_URL).origin
//     const isOrigin = url.origin === apiOrigin

//     const isPositionCheck = /\/books\/\d+/.test(url.pathname)
//     const isBayCheck = /\/bays(\/|$)/.test(url.pathname)

//     return isOrigin && !isPositionCheck && !isBayCheck
//   },
//   new StaleWhileRevalidate({
//     cacheName: 'api-responses',
//     plugins: [
//       new CacheableResponsePlugin({
//         statuses: [0, 200], // Cache successful responses
//       }),
//     ],
//   })
// )

/**
 * API.
 *
 * Caches the asset-manifest
 */
registerRoute(
  ({ url }) => url.pathname.startsWith('/asset-manifest.json'),
  new NetworkFirst({
    cacheName: 'pwa-assets-manifest',
    plugins: [
      new CacheableResponsePlugin({
        statuses: [0, 200],
      }),
      new ExpirationPlugin({ maxEntries: 1 }), // Will cache maximum 1 requests.
    ],
  })
)

/**
 * We use CacheFirst for images because, images are not going to change very often,
 * so it does not make sense to revalidate images on every request.
 *
 * @see https://developers.google.com/web/tools/workbox/guides/common-recipes#caching_images
 */
// We ignore Shelf images for the cache. We always want to grab these from the network.
registerRoute(
  ({ request }) =>
    request.destination === 'image' &&
    !request.url.includes('/assets/shelves/'),
  async ({ request, event }) => {
    // Use a cache-first strategy for the modified request
    const cacheFirst = new CacheFirst({
      cacheName: 'images',
      plugins: [
        new CacheableResponsePlugin({
          statuses: [0, 200], // Cache opaque responses (status 0) and OK responses
        }),
        new ExpirationPlugin({
          maxEntries: 60,
          maxAgeSeconds: 30 * 24 * 60 * 60, // 30 Days
        }),
      ],
    })

    // Clone the request to modify its mode to 'cors'
    const corsRequest = new Request(request.url, { mode: 'cors' })

    // Check the cache first, and if not found, then make the network request
    const response = await cacheFirst.handle({ request: corsRequest, event })

    // If no response from cache or network, throw an error
    if (!response) {
      throw new Error(`The fetch for ${request.url} failed.`)
    }

    // Return the response from cache or fetched with CORS
    return response
  }
)

// @see https://developers.google.com/web/tools/workbox/guides/common-recipes#cache_css_and_javascript_files
registerRoute(
  ({ request }) =>
    request.destination === 'script' ||
    request.destination === 'style' ||
    request.destination === 'font',
  new StaleWhileRevalidate({
    cacheName: 'static-resources',
  })
)
